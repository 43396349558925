import React from "react";
import "./menu.scss";
import PropTypes from "prop-types";
import useSignOut from "@hooks/useSignOut";

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";
import Navbar from "@ui/navbar/navbar";
import UpgradePlan from "../upgradePlan/upgradePlan";
import { User, Upload, Mic, BookOpen, Headphones, MessageSquare, Gift, LogOut } from 'lucide-react';
import { useSelector } from "react-redux";

const Menu = ({ onClose }) => {
  const { logoutUserHandler } = useSignOut(onClose.bind(this));
  const { user } = useSelector(state => state.auth);

  const handleReferralPageEvent = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'viewReferralPage', {
        'event_category': 'View',
        'event_label': 'view Referral Page',
        'email': user.email    
      });        
    } else {
      console.error("gtag is not defined");
    }
    onClose();
  }
  
  return (
    <div className="Menu">
      <header className="Menu__header">
        <Logo className="Menu__header-logo" />
        <div className="Menu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      <Navbar className="Menu__navbar">
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Profilo"
          icon={<User />}
          to="/home/profilo"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Carica Appunti"
          icon={<Upload />}
          to="/home"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Ripeti"
          icon={<Mic />}
          to="/home/ripeti"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Materiale"
          icon={<BookOpen />}
          to="/home/materiale"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Assistenza"
          icon={<Headphones />}
          to="/assistenza"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Feedback"
          icon={<MessageSquare />}
          to="/home/feedback"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Guadagna 5&euro;"
          icon={<Gift />}
          to="/home/referralCode"
          onClick={handleReferralPageEvent}
          end
        />
      </Navbar>
      <div className="Menu__footer">
        <div className="Menu__footer-logout" onClick={logoutUserHandler}>
          <LogOut className="Menu__logout-icon" />
          <p className="Menu__logout-text">logout</p>
        </div>
        <UpgradePlan className="Menu__footer-upgradePlan" onClick={onClose} />
      </div>
    </div>
  );
};

// Menu propTypes
Menu.propTypes = {
  onClose: PropTypes.func,
};

export default Menu;