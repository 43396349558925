import PriceCard from "../../../containers/priceCard/priceCard";
import "./universityPlan.scss";

export const UniversityPlan = ({ isAnnual, setIsAnnual, isLoading, isPremium, handlePremiumSubscription }) => {
  return (
    <div className={isPremium ? 'UniversityPlan__premium' : null}>
      <PriceCard
        isDisabled={isLoading}
        title="Piano Annuale"
        originalCost={"179.99"}
        cost={"79.99"}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        accessInfo={'Accesso per 12 mesi completi'}
        options={[
          "Risparmi 100€ rispetto al piano mensile",
          "Ideale per studenti della maturità e universitari",
          "Funzionalità premium senza limiti",
          "Accesso esclusivo alle nuove funzionalità",
          "Assistenza Prioritaria 24/7",
        ]}
        buttonText={isPremium ? "Gestisci abbonamento" : "Inizia Piano Annuale"}
        onClick={handlePremiumSubscription}
        advised={!isPremium}
        finalText={"Accesso ininterrotto tutto l'anno"}
        />
    </div>
  )
};
