import React, { useState, useEffect } from "react";
import "./audioRecorder.scss";
import PropTypes from "prop-types";
import useAudioRecorder from "./hook/useAudioRecorder";

import { Mic, MicOff, MessageCircle } from "lucide-react";

const AudioRecorder = ({ onHideAudioRecorder, onFeedback, onAddMessage, showChat, isRecording, examState, setExamState, isExamStarted, setShowChat, setIsRecording, setUserAnswer }) => {
  const { stopRecording } = useAudioRecorder(
    onFeedback,
    onHideAudioRecorder,
    onAddMessage,
    setUserAnswer,
  );

  const [isStopDisabled, setIsStopDisabled] = useState(false);

  useEffect(() => {
    if (isRecording) {
      setIsStopDisabled(true);
      const timer = setTimeout(() => {
        setIsStopDisabled(false);
      }, 1000); // Disable for 1 second

      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const handleOpenChat = () => {
    setShowChat(!showChat);
  };

  const handleStopRecording = () => {
    if (!isStopDisabled) {
      setIsRecording(false);
      stopRecording();
      setExamState('processing');
    }
  };

  return (  
    <div>
      <div className={showChat ? "controls chat-active" :  "controls"}>
        <span
          className={`control-button mic ${
            isRecording ? 'recording' : 'not-recording'
          }`}
          onClick={handleStopRecording}
          disabled={examState === 'answered' || isStopDisabled}
        >
          {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
        </span>
        <button
          className={`control-button chat ${isExamStarted ? 'active' : 'inactive'} ${isRecording && 'inactive'}`}
          onClick={handleOpenChat}
          disabled={isRecording}
        >
          <MessageCircle size={24} />
        </button>
      </div>
    </div>
  );
};

AudioRecorder.propTypes = {
  onHideAudioRecorder: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  onAddMessage: PropTypes.func.isRequired,
  showChat: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  examState: PropTypes.string.isRequired,
  setExamState: PropTypes.func.isRequired,
  isExamStarted: PropTypes.bool.isRequired,
  setShowChat: PropTypes.func.isRequired,
  setIsRecording: PropTypes.func.isRequired,
  setUserAnswer: PropTypes.func.isRequired,
};

export default AudioRecorder;