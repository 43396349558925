import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { truncate } from "@utility/truncate";
import { getFileIcon } from "@lib/file";
import { getTagColor } from "../../uploadNotes/documentsQA/hooks/useGetTagColor";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import "./reviewDocument.scss";
import { Image, Music, Video } from "lucide-react";

const useDocumentSelection = (id, examID, title, status, onChange) => {
  const { register } = useForm();
  
  return register("document", {
    onChange: (event) => onChange({ id: event.target.value, examID, title, status })
  });
};

const ReviewDocument = ({ id, examID, title, type, tags, text, status, onChange }) => {
  const documentRegister = useDocumentSelection(id, examID, title, status, onChange);

  const getUpdatedFileIcon = fileType => {
    if(fileType === 'png' || fileType === 'jpeg' ) {
      return <Image className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp3' || fileType === 'vaw') {
      return <Music className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp4') {
      return <Video className="FilteredDocument__left-icon" />;
    } 
  };

  return (
    <li className="FilteredDocument__wrapper">
      <div className="FilteredDocument">
        <div className="FilteredDocument__inner">
          <div className="FilteredDocument__left">
            {getUpdatedFileIcon(type) || <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${title}`} />}
            <div className="FilteredDocument__box">
              <Heading title={title} heading="h5" />
              <p className="FilteredDocument__box-text">{truncate(text, 80)}</p>
            </div>
          </div>
          <div className="FilteredDocument__actions">
            <Field>
              <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
              <input
                className="FilteredDocument__input"
                id={`document-check-${id}`}
                value={id}
                type="radio"
                {...documentRegister}
                />
            </Field>
          </div>
        </div>
        
        <div className="FilteredDocument__mobile-wrapper">
          <Field className="FilteredDocument__mobile-checkbox">
            <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
            <input
              className="FilteredDocument__input"
              id={`document-check-${id}`}
              value={id}
              type="radio"
              {...documentRegister}
              />
          </Field>
          { tags !== null ? 
            <div className="FilteredDocument__tags">
                {tags?.map((tag, idx) => {
                  return (
                    <div key={`${tag}-${idx}`}>
                    <span
                      className="FilteredDocument__tag"
                      style={{ 
                        backgroundColor: getTagColor(idx, tag).backgroundColor, 
                        color: getTagColor(idx, tag).color,
                        borderColor: getTagColor(idx, tag).borderColor,
                      }}
                      >
                      {tag}
                    </span>
                  </div> 
                )})}
            </div>
            
            : null
          }
        </div>
      </div>
    </li>
  );
};

ReviewDocument.propTypes = {
  id: PropTypes.string.isRequired,
  examID: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default ReviewDocument;
