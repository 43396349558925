import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import Heading from "@ui/heading/heading";
import ReviewLoading from "../reviewLoading/reviewLoading";
import ReviewDocument from "../reviewDocument/reviewDocument";

import "./examsInProgress.scss";

const ExamsInProgress = ({ query, onSaveDocument, isLoading, examsInProgress, deleteExam, refreshExams, error, filteredExamsInProgress }) => {

  useEffect(() => {
    console.log("ExamsInProgress montato o query cambiata, esami:", examsInProgress);
  }, [examsInProgress, query]);

  const handleDelete = useCallback(async (examID) => {
    console.log("Richiesta eliminazione esame:", examID);
    await deleteExam(examID);
    console.log("Eliminazione completata, aggiornamento lista");
    refreshExams();
  }, [deleteExam, refreshExams]);

  if (isLoading) {
    return <ReviewLoading text="Caricamento esami da completare..." />;
  }

  if (error) {
    return <p className="ExamsInProgress__error">Errore: {error}</p>;
  }

  console.log("Rendering ExamsInProgress, esami filtrati:", filteredExamsInProgress);

  return (
    <div className="ExamsInProgress">
      <Heading className="ExamsInProgress__title" heading="h3" title="Esami in corso" />
      {filteredExamsInProgress.length === 0 && <p className="ExamsInProgress__text">Nessun esame in corso</p>}
      {filteredExamsInProgress.length > 0 && (
        <ul className="ExamsInProgress__list">
          {filteredExamsInProgress.map(exam => (
            <ReviewDocument
              key={exam.examID}
              id={exam.documentID}
              examID={exam.examID}
              title={exam.title}
              type={exam.type}
              text={exam.text}
              tags={exam.tags ? exam.tags : null}
              status="in-progress"
              onChange={onSaveDocument}
              onDelete={handleDelete}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

ExamsInProgress.propTypes = {
  query: PropTypes.string,
  onSaveDocument: PropTypes.func.isRequired,
};

export default ExamsInProgress;