import "@assets/styles/_index.scss";
import { createRoot } from "react-dom/client";
import { GlobalWorkerOptions } from "pdfjs-dist";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import getApp from "./getApp";

Sentry.init({
  dsn: "https://6a79df7ae43145ec9cd3ea721ed1b1fe@o4508375241195520.ingest.de.sentry.io/4508375245127760",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^http:\/\/8080\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// load cdn
GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

if(process.env.NODE_ENV !== "test") {
  const root = createRoot(document.getElementById("root"));
  if (process.env.NODE_ENV !== "development") {
    console.log = function() {};
    console.debug =function() {};
    console.info = function() {};
    console.warn = function() {};
   console.error = function() {};
}
  root.render(getApp());
};




