import './document.scss';
import PropTypes from 'prop-types';
import { getFileIcon } from '@lib/file';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeDocumentTitle } from '../../../../redux/actions/documentActions';
import { IoMdCheckmark } from "react-icons/io";
import { TbEdit } from "react-icons/tb";

import Heading from '@ui/heading/heading';
import Input from '@ui/input/input';
import { getTagColor } from '../../../uploadNotes/documentsQA/hooks/useGetTagColor';
import { Image, Music, Video } from 'lucide-react';

export const Document = ({ id, type, title, tags, date, isActive, onChange }) => {
  const [isChanging, setIsChanging] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [currentTitle, setCurrentTitle] = useState(title);
  const dispatch = useDispatch();

  const handleEditTitle = () => {
    if (isChanging) {
      if (currentTitle !== inputValue) {
        dispatch(changeDocumentTitle(id, inputValue));
        setCurrentTitle(inputValue)
      }
      setIsChanging(false);
    } else {
      setIsChanging(true);
    }
  };

  const changingInputValue = (e) => {
    const value = e.target.value;
    setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
  };

  const getUpdatedFileIcon = fileType => {
    if(fileType === 'png' || fileType === 'jpeg' ) {
      return <Image className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp3' || fileType === 'vaw') {
      return <Music className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp4') {
      return <Video className="FilteredDocument__left-icon" />;
    } 
  };

  return (
    <li
      className={clsx('Document', isActive && 'Document--active')}
      onClick={() => onChange(id)}
    >
      <div className='Document__card'>
        
        <div className='Document__row'>
        {getUpdatedFileIcon(type) || <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${title}`} />}
          {
            isChanging 
            ? <IoMdCheckmark className='Document__saveIcon' onClick={handleEditTitle}/>
            : <TbEdit className='Document__editIcon' onClick={handleEditTitle}/>  
          }
        </div>

        <div className='Document__box'>
          {isChanging ? (
            <Input 
              className='Document_titleInput'
              type='text'
              value={inputValue}
              onChange={changingInputValue}
            />
          ) : (
            <Heading heading='h6' title={inputValue.trim().length > 20 ? `${inputValue.slice(0,22)}...` : inputValue} />
          )}
          <p className='Document__box-text'>
            <time dateTime={date}>{date}</time>
          </p>
        </div>
        
        { tags !== null ? 
          <div className="Document__tags">
              {tags.map((tag, idx) => {
                return (
                  <div key={`${tag}-${idx}`}>
                  <span
                    className="Document__tag"
                    style={{ 
                      backgroundColor: getTagColor(idx, tag).backgroundColor, 
                      color: getTagColor(idx, tag).color,
                      borderColor: getTagColor(idx, tag).borderColor,
                    }}
                    >
                    {tag}
                  </span>
                </div> 
              )})}
          </div>
          
          : null
        }
      </div>
    </li>
  );
};

// Document prop types
Document.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
