import { useCallback } from "react";
import { useUploadNotesAction } from "../../hook/useUploadNotes";
import { getExtractedQA } from "@lib/file";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export const useGetDocuments = () => {
  const dispatch = useUploadNotesAction();
  const { documentsQA, isLoading } = useSelector(state => state.document);
  const notes = useSelector(state => state.document.processedNotes);
  const navigate = useNavigate();
  const dispatchDoc = useDispatch()
  const { user } = useSelector(state => state.auth);

  // Funzione per unire tutto l'extractedText da processedNotes
  const getText = useCallback(async () => {
    notes.reduce((prev, doc) => ({
      ...prev,
      extractedText: (prev.extractedText || '') + doc.extractedText,
    }), {});

    // Fetch QA basato su processed notes
    dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [...(await getExtractedQA(notes))] });
  }, [notes, dispatchDoc]);

  // Funzione per fetchare i documenti e aggiornare lo stato
  const getDocuments = useCallback(async () => {
    try {
      console.log("processedNotes: ", notes);

      // Imposta lo stato di caricamento
      dispatchDoc({ type: "SET_IS_LOADING", payload: true });
      dispatch({ type: "HANDLE__DISABLED_FORM" });

      if (notes?.length > 1) {
        await getText();
      } else {
        dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [...(await getExtractedQA(notes))] });
      }

      // Reimposta lo stato del form e rimuove il caricamento
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      dispatchDoc({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      // Gestione errori e notifica utente
      toast.error(error.message);
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      dispatchDoc({ type: "SET_IS_LOADING", payload: false });

      // Evento GA4 per errori di caricamento documenti
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'uploadDocError', {
          'event_category': 'Error',
          'event_label': 'Upload Doc Error',
          'email': user.email,
          'error': error.message
        });
      } else {
        console.error("gtag is not defined");
      }
    }
  }, [dispatch, notes, getText, dispatchDoc, user.email]);

  // Funzione per resettare documentsQA
  const resetDocsQA = useCallback(() => {
    dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [] });
    dispatch({ type: "RESET" });
    navigate("/home/ripeti");
    dispatch({ type: "HANDLE_IS_SAVED" });
  }, [dispatchDoc, dispatch, navigate]);

  return {
    isLoading,
    notes,
    documentsQA,
    resetDocsQA,
    getDocuments,
  };
};

export default useGetDocuments;
