import { redirect } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { setDoc, doc, updateDoc } from "firebase/firestore";
import { firestore, auth } from "@redux/firebase";
import { toast } from "react-toastify";
import processFile from "./textExtractor";
import { getQuestion } from "@lib/firestoreQueries";
import { getFeedback } from "@services/openaiApi";
import { getUser } from "@lib/firestoreQueries";

// login action
export const loginAction = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get("email").trim();
  const password = formData.get("password").trim();

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user || !user.emailVerified) {
      throw new Error("Email not verified. Please verify your email.");
    }

    // Trigger GA4 event for successful login
    if (typeof window.gtag === "function") {
      window.gtag("event", "userNormalLogin", {
        event_category: "User",
        event_label: "user normal login",
        email: user.email,
      });

    } else {
      console.error("gtag is not defined");
    }

    // Show success toast

    toast.success("Login avvenuto con successo!");

    // Redirect to home
    return redirect("/home");
  } catch (error) {
    console.error("Login error:", error);
    toast.error(error.message || "Si è verificato un errore durante il login.");
    return { message: error.message };
  }
};

// sign up action
export const signUpAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const user = {
      fullName: formData.get("fullName").trim(),
      email: formData.get("email").trim(),
      emailUseConsent: formData.get("emailUseConsent").trim(),
      study: formData.get("study"),
      documentsUploaded: 0,
      maxDocumentsUpload: 2,
      examsAttempted: 0,
      plan: "Base",
      policyAccepted: formData.get("policyAccepted"),
    };
    const userCredential = await createUserWithEmailAndPassword(auth, user.email, formData.get("password").trim());
    await setDoc(doc(firestore, "students", userCredential.user.uid), user);
    await updateProfile(userCredential.user, { displayName: user.fullName });
    localStorage.setItem("newUser", "true");

    return { isSuccessful: true };
  } catch (error) {
    if (error.code === "auth/email-already-in-use") return { message: "Email già in uso" };
    return { message: error.message };
  }
};

// reset password action
export const resetPasswordAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const email = formData.get("email");

    // check email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) throw new Error("Inserisci un corretto formato");

    await sendPasswordResetEmail(auth, email);
    toast.success("Email per la reimpostazione della password inviata!");
    return redirect("/");
  } catch (error) {
    // check if email is not valid
    if (error.message === "Inserisci un corretto formato") return { message: error.message };

    // send generic error message
    toast.error(error.message);
    return redirect("/reimposta-password");
  }
};

// check verify email action
export const checkVerifyEmailAction = async () => {
  try {
    await getAuth().currentUser.reload();
    const user = getAuth().currentUser;

    // check if user email is verified
    if (!user.emailVerified) return null;

    // retrieve user data
    const userInfo = await getUser(user.uid);
    localStorage.setItem("userId", JSON.stringify(user.uid));

    return { user: userInfo };
  } catch (error) {
    toast.error(error.message);
    return redirect("/controlla-verifica-email");
  }
};

// upload notes action
export const uploadNotesAction = async ({ request }) => {
  const formData = await request.formData();
  const files = Object.values(Object.fromEntries(formData));
  let promiseArray = [];

  for (const file of files) {
    try {
      const processedFile = await processFile(file);

      if (!processedFile) return;

      promiseArray = [...promiseArray, processedFile];
    } catch (error) {
      promiseArray = [...promiseArray, error.file || error];
    }
  }

  return promiseArray;
};

// user profile action
export const userProfileAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const user = Object.fromEntries(formData);

    // update profile
    await updateProfile(auth.currentUser, user);
    const resourceRef = doc(firestore, "students", auth.currentUser.uid);
    await updateDoc(resourceRef, {
      fullName: user.fullName,
      studyAt: user.studyAt,
      email: user.email,
    });
    toast.success("Il tuo profilo è stato aggiornato con successo!");
    return { user };
  } catch (error) {
    const message = error.message;
    toast.error(message);
    return null;
  }
};

// get feedback from chat gpt
export const getFeedbackQuestionAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    // search question on firebase
    const question = await getQuestion(data.messageID);

    // check question exists
    if (!Object.keys(question).length) throw new Error("Domanda non trovata");
    const feedbackData = await getFeedback(data.answer, question.question, question.answer);

    return { feedback: feedbackData.feedback, score: feedbackData.score };
  } catch (error) {
    toast.error(error.message);
    return { error: error.message };
  }
};
