import React from "react";
import "./uploadNotesFiles.scss";

import UploadNoteFile from "./uploadNotesFile/uploadNoteFile";
import { useSelector } from "react-redux";

const UploadNotesFiles = () => {
  const { processedNotes } = useSelector(state => state.document);

  // return null when processed notes are empty
  if (!processedNotes.length) return null;

  return (
    <div className="UploadNotesFiles">
      <ul className="UploadNotesFiles__list">
        {processedNotes?.map((processedNote, index) => {
          return <UploadNoteFile key={index} {...processedNote} file={processedNote} />;
        })}
      </ul>
    </div>
  );
};

export default UploadNotesFiles;
