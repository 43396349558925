import React from "react";
import useSignUp from "./hook/useSignUp";

import Field from "@ui/field/field";
import Input from "@ui/input/input";
import PasswordToggle from "@containers/passwordToggle/passwordToggle";
import AuthButtons from "@containers/authButtons/authButtons";
import "./signUpForm.scss";

const SignUpForm = () => {
  const { Form, register, errors, isSubmitting, handleSubmit, submit, showPassword, handleShowPassword } = useSignUp();

  return (
    <Form
      className="SignUpForm"
      onSubmit={handleSubmit(data => submit(data, { method: "post", action: "/signUp" }))}
      noValidate
    >
      <Field className="SignUpForm__field">
        <Field.Label label="Nome completo" htmlFor="fullName" required />
        <Input id="fullName" name="fullName" type="text" placeholder="Mario Rossi" {...register("fullName")} />
        {errors?.fullName && <Field.Error error={errors.fullName.message} />}
      </Field>
      <Field className="SignUpForm__field">
        <Field.Label label="Grado di studio" htmlFor="study" />
        <select className="SignUpForm__field--select" {...register("study")}>
          <option value="" hidden>
            Seleziona Grado
          </option>
          <option value="Medie">Medie</option>
          <option value="Superiori">Superiori</option>
          <option value="Università">Università</option>
        </select>
      </Field>
      <Field className="SignUpForm__field">
        <Field.Label label="Email " htmlFor="email" required />
        <Input id="email" name="email" type="email" placeholder="Indirizzo email" {...register("email")} />
        {errors?.email && <Field.Error error={errors.email.message} />}
      </Field>
      <Field className="SignUpForm__field SignUpForm__field--password">
        <Field.Label label="Password " htmlFor="password" required />
        <div className="SignUpForm__field-box">
          <Input
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            {...register("password")}
          />
          <PasswordToggle isVisibility={showPassword} onVisibility={handleShowPassword} />
          {errors?.password && <Field.Error error={errors.password.message} />}
        </div>
      </Field>
      <div className="SignUpForm__box">
        <label htmlFor="policyAccepted">
          <Input type="checkbox" id="policyAccepted" {...register("policyAccepted")} />
          Ho letto e accetto i {" "}
          <a
            href="https://www.iubenda.com/termini-e-condizioni/73904869"
            target="_blank"
            rel="noopener noreferrer"
            className="iubenda-white iubenda-noiframe iubenda-embed "
            title="Termini e Condizioni "
          >
            Termini e Condizioni
          </a>{" "}
          e{" "}
          <a
            href="https://www.iubenda.com/privacy-policy/73904869"
            target="_blank"
            rel="noopener noreferrer"
            className="iubenda-white iubenda-noiframe iubenda-embed "
            title="Privacy policy"
          >
            Privacy policy
          </a>{" "}
        </label>
        <label htmlFor="emailUseConsent">
          <Input 
            type="checkbox" 
            id="emailUseConsent" 
            defaultChecked={true}
            {...register("emailUseConsent")}
          />
          Non perderti codici sconto esclusivi e aggiornamenti sulle nuove funzionalità di StudierAI! Ricevi tutto comodamente via email, senza mai ricevere pubblicità indesiderata.
        </label>
      </div>
      <AuthButtons isSubmitting={isSubmitting} textPrimaryBtn="Crea Utente" textSecondaryBtn="Crea Utente con Google" />
    </Form>
  );
};

export default SignUpForm;
