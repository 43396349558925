import {
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_DOCUMENT_TITLE,
  SET_SUBMITTING_DOC,
  SET_DOCUMENTS_TAGS,
  SET_SUMMARY,
  SET_PROCESSED_NOTES,
  SET_FLASHCARDS_QA,

} from './types';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  writeBatch,
  increment,
} from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import { getTypeFile } from '@lib/file';

const BATCH_SIZE = 500;

// Azioni sincrone
export const setExtractedQA = (documentId, extractedQA) => ({
  type: SET_EXTRACTED_QA,
  payload: { documentId, extractedQA },
});

export const setFlashcardsQA = (flashcardsQA) => ({
  type: SET_FLASHCARDS_QA,
  payload: flashcardsQA,
});

export const setDocumentTitle = (documentId, newTitle) => ({
  type: SET_DOCUMENT_TITLE,
  payload: { documentId, newTitle },
});

export const setDocumentTags = (documentId, tags) => ({
  type: SET_DOCUMENTS_TAGS,
  payload: { documentId, tags },
});

export const setProcessedNotes = (file) => ({
  type: SET_PROCESSED_NOTES,
  payload: file,
});

export const setDocumentSummary = (summary) => ({
  type: SET_SUMMARY,
  payload: summary,
});

export const setSubmitDocState = (submitState) => ({
  type: SET_SUBMITTING_DOC,
  payload: submitState,
});

// Utility per controllo feedback valido
const isValidFeedback = (feedback) => {
  return feedback != null && typeof feedback?.isHelpful === 'boolean';
};

// Salva domande e feedback in batch
const saveQABatch = async (questions, feedback = [], documentId, userEmail) => {
  try {
    const batches = [];
    let currentBatch = writeBatch(firestore);
    let operationsCount = 0;

    const validFeedbackCount = feedback.filter(isValidFeedback).length;
    console.log(
      `💾 Preparazione salvataggio: ${questions.length} domande, ${validFeedbackCount} feedback`
    );

    for (let i = 0; i < questions.length; i++) {
      if (operationsCount >= BATCH_SIZE) {
        batches.push(currentBatch);
        currentBatch = writeBatch(firestore);
        operationsCount = 0;
      }

      const qa = questions[i];
      const currentFeedback = qa?.feedback; // Safely get feedback
      const questionRef = doc(collection(firestore, 'questions'));

      const questionData = {
        ...qa,
        documentID: documentId,
        flaggedWrong: false,
        uploadDate: serverTimestamp(),
        lastModified: serverTimestamp(),
      };

      // Add feedback only if valid
      if (isValidFeedback(currentFeedback)) {
        questionData.feedback = {
          isHelpful: currentFeedback?.isHelpful,
          timestamp: serverTimestamp(),
          userId: auth.currentUser.uid,
          createdAt: currentFeedback.timestamp || serverTimestamp(),
        };
      }

      currentBatch.set(questionRef, questionData);
      operationsCount++;

      // Update user stats if feedback is valid
      if (isValidFeedback(currentFeedback)) {
        const userStatsRef = doc(firestore, 'userStats', auth.currentUser.uid);
        currentBatch.set(
          userStatsRef,
          {
            email: userEmail,
            totalFeedbackGiven: increment(1),
           /*  totalDocumentsWithFeedback: increment(1), */
            [`feedback${currentFeedback?.isHelpful ? 'Positive' : 'Negative'}`]:
              increment(1),
            lastFeedbackDate: serverTimestamp(),
          },
          { merge: true }
        );
        operationsCount++;
      }
    }

    const userStatsRef = doc(firestore, 'userStats', auth.currentUser.uid);
      currentBatch.set(
        userStatsRef,
        {
          totalDocumentsWithFeedback: increment(1),
          lastFeedbackDate: serverTimestamp(),
        },
        { merge: true }
      );
    operationsCount++;
      

    if (operationsCount > 0) {
      batches.push(currentBatch);
    }

    // Esegui tutti i batch in sequenza
    console.log(`📦 Esecuzione ${batches.length} batch di salvataggio`);

    for (let i = 0; i < batches.length; i++) {
      try {
        await batches[i].commit();
        const processedQuestions = Math.min(
          (i + 1) * BATCH_SIZE,
          questions.length
        );
        console.log(
          `✅ Batch ${i + 1}/${batches.length} completato (${processedQuestions}/${questions.length} domande)`
        );
      } catch (error) {
        console.error(`❌ Errore nel batch ${i + 1}/${batches.length}:`, error);
        throw error;
      }
    }

    return true;
  } catch (error) {
    console.error('❌ Errore nel salvataggio batch:', error);
    throw error;
  }
};

// Azione principale per salvare i documenti
export const setDocument = (documents, feedbackStates = []) => {
  console.log('DOCUMENT: ', documents);
  return async (dispatch) => {
    try {
      console.log('🚀 Inizio salvataggio documenti');

      // Ensure feedbackStates is an array
      if (!Array.isArray(feedbackStates)) {
        console.warn('⚠️ feedbackStates is not an array; defaulting to empty array.');
        feedbackStates = [];
      }
      
      // Check for current authenticated user
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Utente non autenticato. Effettua il login per continuare.');
      }
      const userId = user.uid;
      const userEmail = user.email;

      const documentsArray = [];
      
      console.log(documents[0])
      // Ottiene il numero totale di feedback dati dall'utente per domanda
      const totalFeedback = documents[0].extractedQA.filter(qa => typeof qa.feedback?.isHelpful === 'boolean');

      console.log(
        `📊 Stato iniziale: ${documents.length} documenti, ${totalFeedback.length} feedback validi`
      );

      console.log('document before saving ', documents)
      for (const document of documents) {
        if (!document.extractedQA?.length) {
          throw new Error('Non ci sono domande e risposte estratte da salvare');
        }

        console.log(`🏷️ Tags before saving:`, document.tags);

        // Define main document data
        const documentData = {
          title: document.title,
          text: document.text,
          type: getTypeFile(document.type),
          uploadDate: serverTimestamp(),
          uploadedBy: userId,
          userEmail: userEmail,
          questionsCount: document.extractedQA.length,
          feedbackCount: totalFeedback.length,
          lastModified: serverTimestamp(),
          tags: document.tags, 
          summary: document.summary, 
        };

        const documentRef = await addDoc(collection(firestore, 'documents'), documentData);

        if (!documentRef.id) {
          throw new Error('Errore nel salvataggio del documento principale');
        }

        // Save questions and feedback in batches
        await saveQABatch(document.extractedQA, feedbackStates, documentRef.id, userEmail);

        // Update Redux
        dispatch(setExtractedQA(documentRef.id, document.extractedQA));
        documentsArray.push({ id: documentRef.id, ...documentData });
        dispatch({ type: SET_DOCUMENTS_TAGS, payload: [] });

        console.log(`📄 Documento "${document.title}" salvato con successo`);
      }

      // Update the Redux store
      dispatch({ type: SET_DOCUMENT, payload: documentsArray });
      console.log('✅ Salvataggio completato con successo');
    } catch (error) {
      console.error('❌ Errore nel salvataggio:', error);
      throw error;
    }
  };
};

export const changeDocumentTitle = (documentId, newTitle) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          title: newTitle,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTitle(documentId, newTitle));
      console.log('✅ Titolo documento modificato con successo');
    } catch (error) {
      console.error('❌ Errore modifica titolo:', error);
      throw error;
    }
  };
};

// Action to change tags for a specific document
export const changeDocumentTags = (documentId, newTags) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      
      // Update the tags field in Firestore
      await setDoc(
        documentRef,
        {
          tags: newTags,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );

      // Dispatch the action to update Redux state
      dispatch({
        type: SET_DOCUMENTS_TAGS,
        payload: newTags,
      });
    } catch (error) {
      console.error('❌ Error updating document tags:', error);
      throw error;
    }
  };
};

// Action to add summary for a specific document
export const addDocumentSummary = (documentId, summary) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);

      // Update the summary field in Firestore
      await setDoc(
        documentRef,
        {
          summary: summary,
        },
        { merge: true }
      );

      // Dispatch the action to update Redux state
      dispatch({
        type: SET_SUMMARY,
        payload: summary,
      });
    } catch (error) {
      console.error("Error updating document summary:", error.message);
    }
  };
};