import React from 'react';
import PropTypes from 'prop-types';
import Feedback from './feedback/feedback';
import './itemQA.scss';

const ItemQA = ({ 
  question, 
  answer, 
  questionNum,
  feedback,
  onFeedback
}) => (
  <li className="ItemQA">
    <div className="ItemQA__content">
      <h3 className="ItemQA__question">
        <span className='ItemQA__question-number'>
          {questionNum}.
        </span>
          {question}
      </h3>
      <p className="ItemQA__answer">{answer}</p>
    </div>
    
    <Feedback 
      feedback={feedback}
      onFeedback={onFeedback}
      question={question}
    />
  </li>
);

ItemQA.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  questionNum: PropTypes.number.isRequired,
  feedback: PropTypes.shape({
    isHelpful: PropTypes.bool,
    timestamp: PropTypes.number,
    userId: PropTypes.string
  }),
  onFeedback: PropTypes.func.isRequired
};

export default React.memo(ItemQA);