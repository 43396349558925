import PdfIcon from "@assets/icons/pdf.png";
import DocxIcon from "@assets/icons/docx.png";
import PptxIcon from "@assets/icons/pptx.png";
import PagesIcon from "@assets/icons/pages.png";
import KeyIcon from "@assets/icons/keynote.png";
import { processDocument } from "@services/openaiApi";

export const getTypeFile = fileType => {
  if (fileType && fileType.split("/").length > 1) return fileType.split("/")[1];
  return fileType;
};

export const getFileSizeString = (bytes) => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};

export const getFileIcon = fileType => {
  switch (getTypeFile(fileType)) {
    case "pdf":
      return PdfIcon;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return DocxIcon;
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return PptxIcon;
    case "vnd.apple.pages":
      return PagesIcon;
    case "vnd.apple.keynote":
      return KeyIcon;
    default:
      return null;
  }
};

const getRawExtractedQA = async extractedText => {
  try {
    const rawExtractedQA = await processDocument(extractedText);

    // Split and parse JSON if the rawExtractedQA response is a single string with multiple JSON objects
    const jsonObjectsFlashCardsQA = rawExtractedQA.qa_response
      .trim()
      .split(/(?<=\})\s*(?=\{)/)
      .map(obj => JSON.parse(obj));


    const jsonObjectsExamQA = rawExtractedQA.interrogation_questions
      .trim()
      .split(/(?<=\})\s*(?=\{)/)
      .map(obj => JSON.parse(obj));
      
      // Extract tags from flashcards QA
      const flashCardsTags = jsonObjectsFlashCardsQA.reduce((acc, obj) => {
        return [...new Set([...acc, ...obj.tags])];
      }, []);

    // Assign tags to exam QA objects
    jsonObjectsExamQA.forEach(obj => {
      obj.tags = flashCardsTags;
    });

    // Reduce to a single unified object for flashcards QA
    const unifiedFlashCardsQA = jsonObjectsFlashCardsQA.reduce((accumulator, current) => {
      const mergedTags = [...new Set([...(accumulator.tags || []), ...current.tags])];
      accumulator.tags = mergedTags.slice(0, 3);

      accumulator.qa_pairs = [...(accumulator.qa_pairs || []), ...current.qa_pairs];
      accumulator.summary = jsonObjectsFlashCardsQA[0].summary;

      return accumulator;
    }, {});

    // Reduce to a single unified object for exam QA
    const unifiedExamQA = jsonObjectsExamQA.reduce((accumulator, current) => {
      const mergedTags = [...new Set([...(accumulator.tags || []), ...current.tags])];
      accumulator.tags = mergedTags.slice(0, 3);

      accumulator.qa_pairs = [...(accumulator.qa_pairs || []), ...current.qa_pairs];
      accumulator.summary = jsonObjectsExamQA[0].summary;

      return accumulator;
    }, {});

    return { unifiedFlashCardsQA, unifiedExamQA };
  } catch (error) {
    console.error('Error processing data:', error);
    throw error;
  }
};

const formatExtractedQA = (rawExtractedQA, document) => {
  const extractedQA = [];
  const examExtractedQA = [];
  let question;
  let answer;
  const tags = rawExtractedQA.unifiedFlashCardsQA.tags;
  const summary = rawExtractedQA.unifiedFlashCardsQA.summary;
  
  for(let i = 0; i < rawExtractedQA.unifiedFlashCardsQA.qa_pairs.length; i++) {
    question = rawExtractedQA.unifiedFlashCardsQA.qa_pairs[i].question;
    answer = rawExtractedQA.unifiedFlashCardsQA.qa_pairs[i].answer;

    extractedQA.push({
      question: question,
      answer: answer,
      tag: tags,
      summary: summary,
    });

    // GA4 trigger event for questions length
    if(question.length < 40) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'docQuestionsLength', {
          'event_category': 'property',
          'event_label': 'get document questions length',
          'short_question': JSON.stringify(question),
        });        
      } else {
        console.error("gtag is not defined");
      }
    }
  }

  for(let i = 0; i < rawExtractedQA.unifiedExamQA.qa_pairs.length; i++) {
    question = rawExtractedQA.unifiedExamQA.qa_pairs[i].question;
    answer = rawExtractedQA.unifiedExamQA.qa_pairs[i].answer;

    examExtractedQA.push({
      question: question,
      answer: answer,
      tag: tags,
      summary: summary,
    });

    // GA4 trigger event for questions length
    if(question.length < 40) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'docQuestionsLength', {
          'event_category': 'property',
          'event_label': 'get document questions length',
          'short_question': JSON.stringify(question),
        });        
      } else {
        console.error("gtag is not defined");
      }
    }
  }

  return {
    id: Date.now().toString(),
    title: document.name.split(".")[0],
    type: document.type,
    text: document.extractedText,
    extractedQA,
    examExtractedQA,
    summary,
    tags
  };
};

export const getExtractedQA = async documents => {
  if(documents.length > 1) {
    try {
      const combinedText = documents
        .filter(doc => !doc.errorMessage) 
        .map(doc => doc.extractedText)   
        .join(' ');                      
  
      if (!combinedText) return [];
  
      const rawExtractedQA = await getRawExtractedQA(combinedText);
  
      const documentQA = formatExtractedQA(rawExtractedQA, {
        name: 'Documento',   
        type: 'multiple',            
        extractedText: combinedText  
      });
  
      return [documentQA]; 
    } catch (error) {
      throw error;
    }
  } else {
    try {
      let documentsQA = [];

      for (const document of documents) {
        if (document.errorMessage) continue;
  
        const rawExtractedQA = await getRawExtractedQA(document.extractedText);
        const documentQA = formatExtractedQA(rawExtractedQA, document);
        documentsQA = [...documentsQA, documentQA];
      }
  
      return documentsQA;
    } catch (error) {
      throw error;
    }
  }
};