import { useCallback } from "react";
import * as Yup from "yup";

const allowedDomains = [
  "gmail.com",
  "icloud.com", 
  "outlook.com",
  "protonmail.com",
  "yahoo.com",
  "hotmail.com",
  "hotmail.it",
];

const domainRegex = new RegExp(`@(${allowedDomains.join("|")})$`, "i");

export const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

export const signInSchema = Yup.object({
  email: Yup.string()
    .matches(domainRegex, "Il dominio dell'email deve essere uno dei seguenti: " + allowedDomains.join(", ") + ".")
    .email("Inserisci un indirizzo email valido")
    .required("Questo campo è obbligatorio"),
  password: Yup.string()
    .min(8, "Inserisci un password con un minimo di 8 caratteri")
    .required("Questo campo è obbligatorio"),
});

export const signUpSchema = Yup.object({
  fullName: Yup.string().required("Questo campo è obbligatorio"),
  email: Yup.string()
    .matches(domainRegex, "Il dominio dell'email deve essere uno dei seguenti: " + allowedDomains.join(", ") + ".")
    .email("Inserisci un indirizzo email valido")
    .required("Questo campo è obbligatorio"),
  password: Yup.string()
    .required("Questo campo è obbligatorio")
    .min(8, "Inserisci un password con un minimo di 8 caratteri"),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string().email("Inserisci un indirizzo email valido").required("Questo campo è obbligatorio"),
});

export const userProfileSchema = Yup.object({
  fullName: Yup.string().required("Questo campo è obbligatorio"),
  email: Yup.string().email("Inserisci un indirizzo email valido").required("Questo campo è obbligatorio"),
});

export const feedbackSchema = Yup.object({
  fullName: Yup.string().required("Questo campo è obbligatorio"),
  email: Yup.string().email("Inserisci un indirizzo email valido").required("Questo campo è obbligatorio"),
  message: Yup.string().required("Questo campo è obbligatorio"),
});

export const assistanceSchema = Yup.object({
  fullName: Yup.string().required("Questo campo è obbligatorio"),
  email: Yup.string().email("Inserisci un indirizzo email valido").required("Questo campo è obbligatorio"),
  message: Yup.string().required("Questo campo è obbligatorio"),
});
