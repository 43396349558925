import { useDispatch } from 'react-redux';

const useDeleteNoteFile = name => {
  const dispatch = useDispatch();

  // delete processed note file
  const deleteNoteFile = () => {
    dispatch({ type: 'DELETE__NOTE_FILE', payload: name })
    dispatch({ type: "SET_GENERATE", payload: true });
  }

  return { deleteNoteFile }
}

export default useDeleteNoteFile
