import React from 'react'
import './materialsDocuments.scss'
import PropTypes from 'prop-types'
import { formatTimeStamp } from './formatTimeStamp'

import MaterialsDocumentsNotFound from './materialsDocumentsNotFound/materialsDocumentsNotFound'
import { Document } from './document/document'

const MaterialsDocuments = ({ documents, selectedDocument, onChange }) => {
  // show message if no documents are present
  if (!documents.length) return <MaterialsDocumentsNotFound />

  return (
    <ul className='MaterialsDocuments'>
      {documents.map(document => {
        return (
          <Document
            key={document.id}
            id={document.id}
            title={document.title}
            type={document.type}
            tags={document?.tags || null}
            date={formatTimeStamp(document.uploadDate)}
            isActive={selectedDocument?.id === document.id}
            onChange={onChange}
          />
        )
      })}
    </ul>
  )
}

// MaterialsDocuments
MaterialsDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  selectedDocument: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default MaterialsDocuments
