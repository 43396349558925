import PriceCard from "../../../containers/priceCard/priceCard";
import "./schoolPlan.scss";

export const SchoolPlan = ({ isAnnual, setIsAnnual, isLoading, isPremium, handlePremiumSubscription }) => {
  return (
    <div className={isPremium ? 'SchoolPlan__premium' : null}>
      <PriceCard
        isDisabled={isLoading}
        title="Piano Accademico"
        originalCost={"134.99"}
        cost={"64.99"}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        accessInfo={'≈ 9 mesi (pausa estiva esclusa)'}
        options={[
          "Risparmi 70€ rispetto al piano mensile",
          "Ideale per studenti alle medie e al liceo",
          "Funzionalità premium senza limiti",
          "Accesso esclusivo alle nuove funzionalità",
          "Assistenza Prioritaria 24/7",
        ]}
        buttonText={isPremium ? "Gestisci abbonamento" : "Inizia Anno Accademico"}
        onClick={handlePremiumSubscription}
        advised={!isPremium}
        finalText={'Sospensione automatica mesi estivi'}
      />
    </div>
  )
};