import React, { useState } from "react";
import "./index.scss";
import useGetDocuments from "@hooks/useGetDocuments";
import useSelectDocument from "./hook/useSelectDocument";

import Heading from "@ui/heading/heading";
import Spinner from "@ui/spinner/spinner";
import MaterialsDocuments from "./materialsDocuments/materialsDocuments";
import Flashcards from "./materialsFlashcards/materialFlashcards";
import { Dot, FileText, LayoutGrid } from "lucide-react";

const Materials = () => {
  const { isLoading, documents } = useGetDocuments("all");
  const [currentLink, setCurrentLink] = useState('flashcards');
  const { selectedDocument, changeDocumentId } = useSelectDocument(documents);

  return (
    <div className="Materials">
      <div className="container">
        <div className="row gy-xs-1 gy-lg-3">
          <div className="col-xs-12">
            <div className={isLoading ? 'Materials__loading-docs' : "Materials__documents"}>
              <Heading className="Materials__title" heading="h4" title="Documenti" />
              <section className="Materials__loader-section">
                {isLoading && 
                  <div className="Materials__loader-section__inner-container">
                    <Spinner size="sm" />
                    <p className="Materials__loader-section__text">Caricamento Materiali...</p>
                  </div>
                }
              </section>
              {!isLoading && (
                <MaterialsDocuments
                  documents={documents}
                  selectedDocument={selectedDocument}
                  onChange={changeDocumentId}
                />
              )}
            </div>
          </div>

          {!isLoading && (
            
            <div className="col-xs-12">
              <Heading className="Materials__title" heading="h4" title="Ripasso" />
              <div className="Materials__menu">
                <div className={`${currentLink === 'flashcards' ? 'Materials__menu__flashcards-link__flashcards-active' : 'Materials__menu__flashcards-link '}`}
                  onClick={() => setCurrentLink('flashcards')}
                  >
                  <div className={`${currentLink === 'flashcards' ? 'Materials__menu__flashcards-icon-bg' : 'Materials__menu__icon-bg'}`}>
                    <LayoutGrid size={18} style={{ minWidth: '18px' }} />
                  </div>
                  
                  <div>
                    <h4 className="Materials__menu__title">
                      Flashcards
                    </h4>
                    
                    <span className="Materials__menu__subtitle">
                      Pratica attiva
                    </span>
                  </div>
                  
                  { 
                    currentLink === 'flashcards' &&
                      <Dot className={`${currentLink === 'flashcards' ? 'Materials__menu__flashcard-circle-icon' : 'Materials__menu__circle-icon'}`} size={50} />
                  } 
                </div>
                
                <div className={`${currentLink === 'summary' ? 'Materials__menu__summary-link__summary-active' : 'Materials__menu__summary-link' }`}
                  onClick={() => setCurrentLink('summary')}
                  >  
                  <div className={`${currentLink === 'summary' ? 'Materials__menu__summary-icon-bg' : 'Materials__menu__icon-bg'}`}>
                    <FileText size={18} style={{ minWidth: '18px' }} />
                  </div>
                  
                  <div>
                    
                    <h4 className="Materials__menu__title">
                      Riassunto
                    </h4>
                    
                    <span className="Materials__menu__subtitle">
                      Lettura e comprensione
                    </span>
                  </div>
                  
                  { 
                    currentLink === 'summary' &&
                      <Dot className={`${currentLink === 'summary' ? 'Materials__menu__summary-circle-icon' : 'Materials__menu__circle-icon'}`} size={50} />
                  } 
                </div>
              </div>

              <Heading className="Materials__title" heading="h4" title={currentLink === "flashcards" ? "Flashcards" : "Riassunto"} />
              { currentLink === "flashcards" 
                ? 
                  <Flashcards idSelectedDocument={selectedDocument?.id} /> 
                : 
                  <div className="Materials__summary-container">
                    <Heading className="Materials__summary-container__title" heading="h5" title={selectedDocument?.title} />
                    <p className={selectedDocument?.summary ? `Materials__summary-container__summary-text` : `Materials__summary-container__summary-no-text`}>
                      {selectedDocument?.summary ? selectedDocument.summary : 'Riassunto non disponibile'}
                    </p>
                  </div> 
              }            
            </div>
  
          )}
        </div>
      </div>
    </div>
  );
};

export default Materials;
