import React, { useState, useCallback, useEffect } from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { TbEdit, TbPlus, TbTag } from "react-icons/tb";
import { BookOpen, LayoutGrid } from "lucide-react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Input from '@ui/input/input';
import ItemQA from "./itemQA/itemQA";
import "./documentQA.scss";
import { addDocumentSummary, changeDocumentTags } from "../../../../redux/actions/documentActions";
import { useDispatch } from "react-redux";
import { getTagColor } from "../hooks/useGetTagColor";

const DocumentQA = ({
 index,
 isActive,
 id,
 title,
 extractedQA,
 documentsQA,
 onUpdateTitle,
 feedbackStates,
 onFeedback,
 documentTag
}) => {
 const [isChanging, setIsChanging] = useState(false);
 const [inputValue, setInputValue] = useState(title);
 const [isEditingTags, setIsEditingTags] = useState(false);
 const [currentTags, setCurrentTags] = useState(documentTag);
 const [newTag, setNewTag] = useState('');
 const [showTagInput, setShowTagInput] = useState(false);
 const [currentLink, setCurrentLink] = useState('flashcards');
const dispatch = useDispatch();

  // Aggiorna l'input quando cambia il titolo da props
  useEffect(() => {
    setInputValue(title);
    dispatch(changeDocumentTags(id, documentsQA[0].tags));
    dispatch(addDocumentSummary(id, documentsQA[0].summary));
  }, [title]);

 // Ordina le domande in ordine crescente alfanumerico
 const sortedQA = [...extractedQA].sort((a, b) => a.question.localeCompare(b.question));
 const position = isActive ? 0 : index === 0 ? 1 : index;

 const handleEditTitle = useCallback(() => {
   if (isChanging) {
     const newTitle = inputValue.trim();
     if (newTitle && title !== newTitle) {
       console.log('📝 Aggiornamento titolo temporaneo:', {
         id,
         oldTitle: title,
         newTitle
       });
      
       onUpdateTitle(newTitle);
     } else {
       // Se il titolo è vuoto o non è cambiato, ripristina il valore precedente
       setInputValue(title);
     }
     setIsChanging(false);
   } else {
     setIsChanging(true);
   }
 }, [isChanging, title, inputValue, id, onUpdateTitle]);


  const changingInputValue = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
  }, []);


  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleEditTitle();
    } else if (e.key === 'Escape') {
      setInputValue(title);
      setIsChanging(false);
    }
  }, [handleEditTitle, title]);

  const handleAddTag = (e) => {
    if ((e.key === 'Enter' || e.type === 'blur') && newTag.trim()) {
      const tagToAdd = newTag.trim();
      if (!currentTags.includes(tagToAdd)) {
        const updatedTags = [...currentTags, tagToAdd];
        setCurrentTags(updatedTags);
        dispatch(changeDocumentTags(id, updatedTags));
        setNewTag('');
        setShowTagInput(false);
      }
    } else if (e.key === 'Escape') {
      setShowTagInput(false);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
    setCurrentTags(updatedTags);
    dispatch(changeDocumentTags(id, updatedTags));
  };

  const handleBlur = useCallback(() => {
    handleEditTitle();
  }, [handleEditTitle]); 
 
 return (
   <div
     style={{ "--elPos": position }}
     className={clsx("DocumentQA", isActive && "DocumentQA--active")}
   > 
     <header className="DocumentQA__header">
       <div className="DocumentQA__title-container">
         {isChanging ? (
           <Input
             className="DocumentQA__title-Input"
             type="text"
             value={inputValue}
             onChange={changingInputValue}
             onKeyDown={handleKeyPress}
             onBlur={handleBlur}
             autoFocus
             />
            ) : (
              <h2 className="DocumentQA__title">{title.length > 25 ? title.slice(0, 25) + '...' : title}</h2>
            )}
         <div className="DocumentQA__actions">
         <TbTag
            className={clsx(
              "DocumentQA__tagIcon",
              isEditingTags && "DocumentQA__tagIcon--active"
            )}
            onClick={() => setIsEditingTags(!isEditingTags)}
            />
           {isChanging
             ? <IoMdCheckmark className="DocumentQA__saveIcon" onClick={handleEditTitle}/>
             : <TbEdit className="DocumentQA__editIcon" onClick={handleEditTitle}/>
            }
         </div>
       </div>
        
        <div className="DocumentQA__tags-wrapper">
          <div className="DocumentQA__tags-section">
            {currentTags.map((tag, idx) => {
              return (
                <div key={`${tag}-${idx}`} className="DocumentQA__tags">
                <span
                  className={clsx(
                    "DocumentQA__tag",
                    isEditingTags && "DocumentQA__tag--editing" 
                  )}
                  style={{ 
                    backgroundColor: getTagColor(idx, tag).backgroundColor, 
                    color: getTagColor(idx, tag).color,
                    borderColor: getTagColor(idx, tag).borderColor,
                  }}
                  >
                  {tag}
                  {isEditingTags && (
                    <IoMdClose
                    className="DocumentQA__tag-remove"
                    onClick={() => handleRemoveTag(tag)}
                    />
                  )}
                </span>
              </div> 
              )})}
                {isEditingTags && !showTagInput && (
                  <button
                  className="DocumentQA__add-tag"
                  onClick={() => setShowTagInput(true)}
                  >
                    <TbPlus className="DocumentQA__add-icon" />
                    Aggiungi
                  </button>
                )}
            {isEditingTags && showTagInput && (
              <div className="DocumentQA__tag-input-wrapper">
                <Input
                  className="DocumentQA__tag-input"
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={handleAddTag}
                  onBlur={handleAddTag}
                  placeholder="Premi invio per aggiungere..."
                  autoFocus
                  />
              </div>
            )}
          </div>

          { 
            <div className="DocumentQA__menu">
              <div className={`DocumentQA__menu__flashcards-link 
                ${currentLink === 'flashcards' && 'DocumentQA__menu__flashcards-link__flashcards-active'}`}
                onClick={() => setCurrentLink('flashcards')}
                >
                <LayoutGrid size={19} />
                <span>
                  Flashcard
                </span>
              </div>
              
              <div className={`DocumentQA__menu__summary-link 
                ${currentLink === 'summary' && 'DocumentQA__menu__summary-link__summary-active'}`}
                onClick={() => setCurrentLink('summary')}
                >
              <BookOpen size={19} />
                <span>
                  Riassunto
                </span>
              </div>
            </div>
          }
        </div>
      </header>
      
      { currentLink === 'flashcards' ?
      
          <div className="DocumentQA__body">
          {sortedQA.length > 0 ? (
            <ul className="DocumentQA__list">
              {sortedQA.map((qa, questionIndex) => (
                <ItemQA
                key={`${qa.question}-${questionIndex}`}
                questionNum={questionIndex + 1}
                question={qa.question}
                answer={qa.answer || qa.answers || ""}
                feedback={feedbackStates[questionIndex]}
                onFeedback={(isHelpful) => onFeedback(questionIndex, isHelpful)}
                />
              ))}
            </ul>
          ) : (
            <p>Nessuna domanda estratta</p>
          )}
          </div>

        : <div className="DocumentQA__summary-container">
            <p className="DocumentQA__summary-container__text">{documentsQA[0]?.summary ? documentsQA[0]?.summary : 'Qualcosa è andato storto, ricarica il file per ottenere il riassunto' }</p>
          </div>
      }
   </div>
 );
};


DocumentQA.propTypes = {
 index: PropTypes.number.isRequired,
 isActive: PropTypes.bool.isRequired,
 id: PropTypes.string,
 title: PropTypes.string.isRequired,
 extractedQA: PropTypes.arrayOf(
   PropTypes.shape({
     question: PropTypes.string.isRequired,
     answer: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
     answers: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
   })
 ).isRequired,
 documentsQA: PropTypes.array.isRequired,
 onUpdateTitle: PropTypes.func.isRequired,
 feedbackStates: PropTypes.array,
 onFeedback: PropTypes.func
};


export default DocumentQA;


