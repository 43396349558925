import { SET_LIMIT_REACHED, SET_POPUP_VIEWD, UPGRAGE_CLICKED, LAST_POPUP_DATE, POPUP_CLOSED, SET_LIMIT_EXAMS_REACHED } from '../actions/types';

const initialState = {
  limitReached: 0,
  limitExamsReached: 0,      
  popupViewed: 0,    
  upgradeClicked: 0,
  popupClosed: 0,
  lastPopupDate: 0,
};

const leadScoreReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIMIT_REACHED:
      return {
        ...state,
        limitReached: action.payload,
      };
    case SET_LIMIT_EXAMS_REACHED:
      return {
        ...state,
        limitExamsReached: state.limitExamsReached + 1,
      };
    case SET_POPUP_VIEWD: 
      return {
        ...state,
        popupViewed: state.popupViewed + 1,
      };
    case UPGRAGE_CLICKED: 
      return {
        ...state,
        popupViewed: state.popupViewed + 1,
      };
    case POPUP_CLOSED: 
      return {
        ...state,
        popupClosed: state.popupClosed + 1,
      };
    case LAST_POPUP_DATE: 
      return {
        ...state,
        lastPopupDate: action.payload,
      };
    default:
      return state;
  }
};

export default leadScoreReducers;