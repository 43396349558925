// Color definitions for mainSubject
export const subjectColors = {
  psicologia: { backgroundColor: '#fffbeb', color: '#854d0e', borderColor: '#854d0e' },
  storia: { backgroundColor: '#fef2f2', color: '#7f1d1d', borderColor: '#7f1d1d' },
  matematica: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  aritmetica: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  geometria: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  economia: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  algebra: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  filosofia: { backgroundColor: '#f5f3ff', color: '#4c1d95', borderColor:'#4c1d95' },
  fisica: { backgroundColor: '#ecfdf5', color: '#065f46', borderColor: '#065f46' },
  chimica: { backgroundColor: '#f0fdfa', color: '#134e4a', borderColor: '#134e4a' },
  arte: { backgroundColor: '#fff1f2', color: '#9f1239', borderColor: '#9f1239' },
  letteratura: { backgroundColor: '#fff7ed', color: '#9a3412', borderColor: '#9a3412' },
  biologia: { backgroundColor: '#f0fdf4', color: '#14532d', borderColor: '#14532d' },
  scienze: { backgroundColor: '#f0fdf4', color: '#14532d', borderColor: '#14532d' },
  programmazione: { backgroundColor: '#ecfeff', color: '#155e75', borderColor: '#155e75' },
  algoritmi: { backgroundColor: '#ecfeff', color: '#155e75', borderColor: '#155e75' },
  default: { backgroundColor: '#F0F9F2', color: '#1D5F39', borderColor: '#54976c' } 
};

// Function to get color for a tag
export const getTagColor = (tagIndex, tag) => {
  switch (tagIndex) {
    case 0: 
    return subjectColors[tag] || subjectColors.default; 
    case 1:
      return { backgroundColor: '#f8fafc', color: '#475569', transition: 'background-color 0.2s' }; // slate
    case 2:
      return { backgroundColor: '#e0e0e0', color: 'black', borderColor: '#a7a2a2', transition: 'background-color 0.2s' }; // green
    default:
      return { backgroundColor: '#f3f4f6', color: '#374151', transition: 'background-color 0.2s' }; // default gray
  }
};