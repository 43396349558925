import './uploadNotesCard.scss'

import UploadNotesForm from './uploadNotesForm/uploadNotesForm'
import UploadNotesFiles from './uploadNotesFiles/uploadNotesFiles'
import { useSelector } from 'react-redux'

const UploadNotesCard = () => {
  const files = useSelector(state => state.document.processedNotes);
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={`UploadNotesCard ${user.plan === 'Base' ? 'UploadNotesCard__free' : null}`}>
      <UploadNotesForm />
      <UploadNotesFiles />
      {files.length > 0 && <UploadNotesForm addMoreFiles={true} />}
    </div>
  )
}

export default UploadNotesCard
