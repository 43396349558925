import React from 'react'
import PropTypes from 'prop-types'
import useGetFlashcards from './hook/useGetFlashcards'
import { useSelector } from "react-redux";

import { Flashcard } from './flashcard/flashcard'
import MaterialsFlashcardsNotFound from './materialsFlashcardsNotFound/materialsFlashcardsNotFound'
import Spinner from '@ui/spinner/spinner'
import './materialFlashcards.scss';

const MaterialFlashcards = ({ idSelectedDocument }) => {
  const { isLoading, flashcards } = useGetFlashcards(idSelectedDocument)
  const { flashcardsQA } = useSelector(state => state.document);

  // Sort flashcard in ascending order
  const sortedFlashcards = [...(flashcardsQA || flashcards)].sort((a, b) => a.question.localeCompare(b.question));
  
  // Sorted flashcards with discarded questions from user
  const updatedQA = sortedFlashcards.filter(qa => qa.feedback?.isHelpful !== false); 

  // return select document if id selected document is null
  if (!idSelectedDocument)
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />

  // return empty flashcards if flashcards is empty
  if (!isLoading && updatedQA.length === 0)
    return (
      <MaterialsFlashcardsNotFound message='Nessuna flashcards trovata del documento selezionato' />
    )

  // show spinner if is loading
  if (isLoading) return <Spinner size='md' />

  return (
    <div className='MaterialsFlashcards__container row gy-xs-1 g-md-1 g-lg-3'>
      {(sortedFlashcards || updatedQA).length > 0 &&
        (sortedFlashcards || updatedQA).map((flashcard, index) => (
          <Flashcard key={index} question={flashcard.question} answer={flashcard.answer} />
        ))}
    </div>
  )
}

// MaterialFlashcards propTypes
MaterialFlashcards.propTypes = {
  idSelectedDocument: PropTypes.string,
}

export default MaterialFlashcards
