import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useUploadFiles from "./hooks/useUploadFiles";
import useDropFiles from "./hooks/useDropFiles";
import useGetDocuments from "../../documentsQA/hooks/useGetDocuments";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Plus, Upload, AlertCircle } from "lucide-react";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import Input from "@ui/input/input";
import TextArea from "@ui/textarea/textarea";
import Button from "@ui/button/button";
import ChatModalFreeSubscription from "../../../chat/chatCard/chatModalFreeSubscription/chatModalFreeSubscription";
import { toast } from "react-toastify";
import { setSubmitDocState } from "../../../../redux/actions/documentActions";

import { setDocUploadProgress } from "../../../../redux/actions/docUploadProgressAction";
import { setPopupViewed, setLimitReached } from "../../../../redux/actions/loadScoreActions";
import "./uploadNotesForm.scss";

const UploadNotesForm = ({ addMoreFiles }) => {
  const { fetcher, register, disabledForm, onNotesChange, uploadNotes, showTextArea, saveText, closeTextArea } = useUploadFiles();
  const { isDragging, onDragOver, onDragLeave, dropFiles } = useDropFiles(uploadNotes);
  const { user } = useSelector(state => state.auth);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const { isLoading, isGenerate } = useSelector(state => state.document);
  const { getDocuments } = useGetDocuments()
  const dispatch = useDispatch();
  const isSubmitting = fetcher.state === "submitting";

  const files = useSelector(state => state.document.processedNotes);
  const currFiles = files;
  
  // Salvataggio state per assicurarsi di renderizzare il Loader al momento giusto
  useEffect(() => {
    if(isSubmitting) {
      dispatch(setSubmitDocState(isSubmitting));
    } else {
      dispatch(setSubmitDocState(false));
    }
  }, [isSubmitting, dispatch, files]);
  
  useEffect(() => {
    console.log(currFiles)
    if(currFiles.length !== files) {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
   
    //End upload if it's a pages file
    if(showTextArea && files.length === 0) {
      dispatch({ type: "SET_IS_LOADING", payload: false });  
    }
  }, [files, currFiles, dispatch, showTextArea]);

  const handleAddFile = async (event) => {
    event.preventDefault();    

    // Ensure that files exist before proceeding
    const file = event.target.files && event.target.files[0];
    if (!file) {
      toast.warning('Nessun file selezionato. Aggiungere un file.');
      return;
    };

    // Verify if file's size is greather then 24mb
    const fileDimension = parseInt(event.target.files[0]?.size / 1024 / 1024);
    const fileType = event.target.files[0].type;
    
    if(fileType.startsWith('audio/') || fileType.startsWith('video/')) {
      dispatch(setDocUploadProgress('audio'));
    };
    
    if(fileDimension > 80 && (!fileType.startsWith('audio/') || !fileType.startsWith('video/'))) {
      toast.info('Inserisci un file di dimensione minore di 80mb (se non è un file video/audio, prova a rimuovere le foto dal file e riprova)');
      return;
    };
    
    if(fileDimension > 24 && (fileType.startsWith('audio/') || fileType.startsWith('video/'))) {
      toast.info('Inserisci un file audio/video di dimensione minore di 25mb');
      return;
    };
    
    dispatch({ type: "SET_IS_LOADING", payload: true });

    if ((files.length >= 2 || filesUploaded === '2 / 2') && user?.plan !== 'Premium') {
      setShowModal(true);
      dispatch(setLimitReached(true));
      dispatch(setPopupViewed());
      setIsFreeAccount(true);
      dispatch({ type: "SET_IS_PROCESSING", payload: false });
      dispatch({ type: "SET_IS_LOADING", payload: false });

      // trigger event for limit documents uploaded reached
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'limitDocumentsUploadedReached', {
          'event_category': 'Account',
          'event_label': 'limit documents uploaded reached',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }
      
      return;
    }
  
    dispatch({ type: "SET_GENERATE", payload: true });
    onNotesChange(event);
  };

  const handleGenerateExam = () => {
    dispatch({ type: "SET_PROCESSING_STATE", payload: true });
    dispatch({ type: "SET_GENERATE", payload: false });
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch(setSubmitDocState(isSubmitting));
    getDocuments();
  };  
  
  useEffect(() => {
    if(user?.plan !== 'Premium' && (user?.documentsUploaded === 2 || (user?.documentsUploaded === 1 && files.length >= 1))) {
      setFilesUploaded('2 / 2');
    }  else if(user?.plan !== 'Premium' && user?.documentsUploaded === 1) {
      setFilesUploaded('1 / 2');
    } else {
      setFilesUploaded(`${files.length} / 2`);
    } 
  }, [user.documentsUploaded, files.length, user.plan]);
  
  return (
    <>
      {(showModal && isFreeAccount) && <ChatModalFreeSubscription onClose={() => setShowModal(false)} uploadingDoc={true} />}
      <fetcher.Form
        className={clsx(
          `UploadNotesForm ${user?.plan !== 'Premium' && 'UploadNotesForm__free-plan'} ${addMoreFiles && 'second-form'}`,
          (isDragging && user?.plan !== 'Premium') ? "UploadNotesForm--active-free" : (isDragging && user?.plan === 'Premium') ? "UploadNotesForm--active" : null,
          (((isLoading || disabledForm || showTextArea) ) || (!addMoreFiles && files.length > 0)) && "UploadNotesForm--disabled",
        )}
        onDrop={dropFiles}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        action="/home?index"
        method="post"
        encType="multipart/form-data"
      >
        <div className="UploadNotesForm__inner">
          {
            addMoreFiles ? 
              <>
                {!isDragging && (
                  <Field className={`UploadNotesForm__field ${addMoreFiles && 'UploadNotesForm__field-second-form'}`}>
                    {<Field.Label className="UploadNotesForm__field-label" label="Aggiungi File" htmlFor="notes" />}
                    <Input
                      className={`UploadNotesForm__field-input ${addMoreFiles && 'UploadNotesForm__input-second-form'}`}
                      id="notes"
                      name="notes"
                      type="file"
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}
                      onChange={(event) => handleAddFile(event)}
                    />
                  </Field>
                )}
                <div className="second-form__wrapper">
                  <Plus size={20} className="second-form__more-files-icon" />
                  <span className="second-form__text">Aggiungi un altro file</span>
                </div>
              </>
              
            : 
              <>
                <div className="UploadNotesForm__icon-wrapper">
                  <Upload size={40} className="UploadNotesForm__icon" /> 
                </div>
            
                <div /* className={isDragging ? `UploadNotesForm__dragging` : null} */>         
                  <Heading className="UploadNotesForm__title" title="Trascina o Seleziona i file da caricare" heading="h5" />  
                  <p className="UploadNotesForm__text">
                    Importa documenti di testo, presentazioni o file multimediali per generare le domande
                  </p>
                </div>
  
                {!isDragging && files.length === 0 ?  (

                  <Field className={`UploadNotesForm__field ${user?.plan === 'Premium' && 'UploadNotesForm__field'}`}>
                    <span className="UploadNotesForm__field-label-wrapper">
                      <Plus className="UploadNotesForm__field-label-icon" size={19} />
                      <Field.Label className="UploadNotesForm__field-label" label="Seleziona File" htmlFor="notes" />
                    </span>
                    <Input
                      className="UploadNotesForm__field-input"
                      id="notes"
                      name="notes"
                      type="file"
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}
                      onChange={(event) => handleAddFile(event)}
                    />
                  </Field>)
                  
                  : null
                }
              </>
          }
        </div>
      </fetcher.Form>
      <>
      
      {(user?.plan !== 'Premium' && !addMoreFiles && files.length === 0) && (
        <div className="UploadNotesForm__info-container-first-form">
          <div className="UploadNotesForm__alert-wrapper">
            <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
            <p className="UploadNotesForm__info">
              <strong>Documenti caricati:</strong> {filesUploaded}
            </p>
          </div>
        </div>
      )}
        
      {user?.plan !== 'Premium' && addMoreFiles
        ?
          <div className="UploadNotesForm__form-footer">
            {user?.documentsUploaded === undefined && (
             <>
              <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
              <p className="UploadNotesForm__info">
                <strong>Documenti caricati:</strong> {filesUploaded}
              </p>
             </>
            )}
            {typeof user.documentsUploaded === "number" && (
              <div className="UploadNotesForm__alert-wrapper">
                <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
                <p className="UploadNotesForm__info">
                  <strong>Documenti caricati:</strong> {filesUploaded}
                </p>
              </div>
            )}

            {
              (files.length && !isSubmitting && (!isLoading)) && isGenerate
               
              ? 
                <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !files.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                  Genera Esame
                </button>
                
              : null
            }
          </div> 
        
        : 
          (files.length && !isSubmitting && (!isLoading) && addMoreFiles) && isGenerate
            
          ? <div className="UploadNotesForm__generate-container">
              <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !files.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                Genera Esame
              </button>
            </div>
            
          : null 
      }
      </>
      {showTextArea && (
        <div className="UploadNotesForm__textarea-container">
          <p className="UploadNotesForm__error">
            <span className="UploadNotesForm__error-title">Problema nel caricamento</span><br /><br />
            Non possiamo leggere testo dai file Pages o Keynote,
            per favore copia e incolla il tuo testo qui
            o converti il file in PDF e caricalo sopra.
          </p>
          <button onClick={closeTextArea} className="UploadNotesForm__close-button">
            <IoClose className="UploadNotesForm__close-icon" />
          </button>
          <TextArea
            id="manualText"
            className="UploadNotesForm__textarea"
            placeholder="Inserisci il testo qui..."
            {...register("manualText")}
          />
          <Button onClick={saveText} className="UploadNotesForm__save-button">Salva</Button>
        </div>
      )}
    </>
  );
};

export default UploadNotesForm;
