import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setDocument } from '@redux/actions/documentActions';
import { setUser } from '@redux/actions/authActions';
import { firestore } from '@redux/firebase';
import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';

const useSaveDocuments = (onReset) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth); // Ottieni l'oggetto user
  const userDoc = useSelector(state => state.document);

  const updateDocumentsCount = async (userID) => {
    try {
      const studentRef = doc(firestore, "students", userID);
      const docSnap = await getDoc(studentRef);

      if (!docSnap.exists()) {
        throw new Error("Utente non trovato");
      }

      const { documentsUploaded = 0 } = docSnap.data();

      // Trigger event per il primo documento caricato
      window.dataLayer = window.dataLayer || [];
      if (documentsUploaded === 0) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'firstDocumentUploaded', {
            'event_category': 'Documents',
            'event_label': 'First Document Uploaded',
            'email': user.email // Ora 'user' è definito
          });
        } else {
          console.error("gtag is not defined");
        }
      }

      // Aggiorna il conteggio dei documenti
      await updateDoc(studentRef, {
        documentsUploaded: increment(1),
      });

      // Aggiorna i dati utente in Redux
      const updatedDoc = await getDoc(studentRef);
      if (updatedDoc.exists()) {
        dispatch(setUser(updatedDoc.data()));
      }
    } catch (error) {
      console.error('Errore nell\'aggiornamento del conteggio documenti:', error);
      throw error;
    }
  };

  const saveAllDocuments = async ({ documentsQA, feedbackStates = [] }) => {
    if (isLoading) {
      console.log('⚠️ Salvataggio già in corso');
      return;
    }

    try {
      setIsLoading(true);
      console.log('🚀 Inizio salvataggio documenti');

      // Ottieni l'ID utente dal localStorage
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("Utente non autenticato");
      }

      // Salva i documenti in Redux
      await dispatch(setDocument(documentsQA, `${userDoc.docTags}`, feedbackStates));

      // Aggiorna il conteggio dei documenti
      await updateDocumentsCount(userID);
      
      // Ottiene il numero totale di feedback dati dall'utente per domanda
      const totalFeedback = feedbackStates.filter(feedback => typeof feedback?.isHelpful === 'boolean');

      toast.success(
        feedbackStates.length > 0
          ? `Salvati ${documentsQA.length} documenti con ${totalFeedback.length} feedback`
          : 'Documenti salvati con successo'
      );

      onReset();
    } catch (error) {
      console.error('❌ Errore nel salvataggio:', error);
      toast.error(error.message || 'Errore durante il salvataggio');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, saveAllDocuments };
};

export default useSaveDocuments;
