import { 
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_SUBMITTING_DOC, 
  SET_PROCESSING_STATE, 
  SET_GENERATE, 
  SET_IS_LOADING,
  SET_DOCUMENTS_QA,
  SET_DOCUMENTS_TAGS,
  SET_SUMMARY,
  SET_PROCESSED_NOTES,
  DELETE__NOTE_FILE,
  RESET_PROCESSED_NOTES,
  SET_FLASHCARDS_QA,
} from '../actions/types';

import { matchFileName } from '../../context/uploadNotes/matchFileName';

const initialState = {
  documents: [],
  extractedQA: [],
  flashcardsQA: [],
  isSubmitting: false,
  isProcessing: false,
  isGenerate: false,
  isLoading: false,
  documentsQA: [],
  tags: [],
  summary: '',
  processedNotes: [],
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        documents: [...action.payload],
      };
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case SET_PROCESSED_NOTES:
      return {
        ...state,
        processedNotes: [...state.processedNotes, action.payload],
      };
    case RESET_PROCESSED_NOTES:
      return {
        ...state,
        processedNotes: [],
      };
    case DELETE__NOTE_FILE: {
      const copyArray = [...state.processedNotes];

      // search file by name and delete it
      const indexFile = copyArray.findIndex(note => matchFileName(note.name, action.payload));

      // if file not found, return state
      if (indexFile === -1) return state;
      copyArray.splice(indexFile, 1);

      return { ...state, processedNotes: [...copyArray] };
    }
    case SET_DOCUMENTS_TAGS: 
      return {
        ...state, 
        tags: action.payload,
      };
    case SET_EXTRACTED_QA:
      return {
        ...state,
        extractedQA: action.payload,
      };
    case SET_DOCUMENTS_QA:
      return {
        ...state,
        documentsQA: action.payload,
      };
    case SET_FLASHCARDS_QA:
      return {
        ...state,
        flashcardsQA: action.payload,
      };
    case SET_PROCESSING_STATE:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case SET_GENERATE:
      return {
        ...state,
        isGenerate: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SUBMITTING_DOC:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
