import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Button from "@ui/button/button";
import PriceOption from "./priceOption/priceOption";

import "./priceCard.scss";

const PriceCard = ({ 
  title, 
  originalCost, 
  cost, 
  method = "", 
  advised = false, 
  options, 
  buttonText, 
  onClick, 
  isDisabled = false, 
  popularTag,
  freeNote,
  finalText,
  accessInfo,
  isAnnual,
  showCountdown = true
}) => {
  return (
    <div className={clsx("PriceCard", advised && `${title === 'Piano Accademico' ? "PriceCard--suggest-scolastico " :  title === 'Piano Universitario' ? "PriceCard--suggest-universitario" : 'PriceCard--suggest'}`)}>
      {popularTag && <div className="PriceCard__popular-tag">{popularTag}</div>}
      <div className="PriceCard__header">
        <h3 className="PriceCard__header-title">{title}</h3>
        <span className="PriceCard__header-cost">
          {originalCost && <span className="PriceCard__original-cost">{originalCost}€</span>}
          <span className="PriceCard__current-cost">{cost}€</span>
          {method && <span className="PriceCard__method">/ {method}</span>}
        </span>
        {isAnnual &&
          <div className={`${title === 'Piano Accademico' ? 'PriceCard__access-info-scolastico' : 'PriceCard__access-info'}`}>
            <span className="PriceCard__access-info-text">
              {accessInfo}
            </span>
          </div>
        }
      </div>
      <ul className="PriceCard__options">
        {options.map((option, index) => (
          <PriceOption key={index} option={option} title={title} />
        ))}
      </ul>
      <div className='PriceCard__button'>
        <Button className={title === 'Piano Accademico' ? 'PriceCard__button-scolastico' : null} disabled={isDisabled} size="md" variant="primary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
      {freeNote && <p className="PriceCard__text PriceCard__text--black">{freeNote}</p>}
      {Number(cost) > 0 && <span className="PriceCard__final-text PriceCard__text--black">{finalText || "Puoi disdire quando vuoi"}</span>}
    </div>
  );
};

PriceCard.propTypes = {
  title: PropTypes.string.isRequired,
  originalCost: PropTypes.string,
  cost: PropTypes.string.isRequired,
  method: PropTypes.string,
  advised: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  popularTag: PropTypes.string,
  freeNote: PropTypes.string,
  showCountdown: PropTypes.bool
};

export default PriceCard;