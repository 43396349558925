import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ui/button/button';
import useSaveDocuments from '../hooks/useSaveDocuments';
import { useDispatch } from 'react-redux';
import { RESET_PROCESSED_NOTES } from '../../../../redux/actions/types';
import { useNavigate } from 'react-router-dom';

const DocumentsQASaveButton = ({ documentsQA, onReset, feedbackStates = [] }) => {
  const { isLoading, saveAllDocuments } = useSaveDocuments(onReset);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSave = () => {
    saveAllDocuments({ documentsQA, feedbackStates });
    navigate("/home/ripeti");
    dispatch({ type: RESET_PROCESSED_NOTES });
  };

  return (
    <Button
      size="sm-2"
      variant="primary"
      onClick={handleSave}
      disabled={isLoading}
      title={`Salva ${documentsQA.length} domande`}
    >
      Salva esame
    </Button>
  );
};

DocumentsQASaveButton.propTypes = {
  documentsQA: PropTypes.array.isRequired,
  onReset: PropTypes.func.isRequired,
  feedbackStates: PropTypes.arrayOf(
    PropTypes.shape({
      isHelpful: PropTypes.bool,
      timestamp: PropTypes.number,
      userId: PropTypes.string,
    })
  ),
};

export default React.memo(DocumentsQASaveButton);
