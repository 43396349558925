import { AnnualPlans } from "./AnnualPlans/AnnualPlans";
import { MonthlyPlan } from "./MonthlyPlan/MonthlyPlan";
import { SchoolPlan } from "./SchoolPlan/SchoolPlan";
import { UniversityPlan } from "./UniversityPlan/UniversityPlan";

export const useGetPlans = () => {
   const renderSelectedPlan = (selectedPlan, isPremium, isLoading, isAnnual, setIsAnnual, handlePremiumSubscription) => {
    switch (selectedPlan) {
      case 'Annual University':
        return (
          <div className="Plans__plan-container">
            <UniversityPlan
              isPremium={isPremium}
              isLoading={isLoading}
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
              priceId={"price_1QRZdcHFdskyUb7jzQNtWplU"}
              handlePremiumSubscription={handlePremiumSubscription}
            />
          </div>
        )
          
      case 'Annual School':
        return (
          <div className="Plans__plan-container">
            <SchoolPlan
              isPremium={isPremium}
              isLoading={isLoading}
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
              priceId={"price_1QRpr1HFdskyUb7j9bONPjNt"}
              handlePremiumSubscription={handlePremiumSubscription}
            />
          </div>
        )
      default: return <MonthlyPlan 
        isPremium={isPremium}
        isLoading={isLoading}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        handlePremiumSubscription={handlePremiumSubscription}
      />
    };
  };

  const renderUnselectedPlan = (selectedPlan, isLoading, isAnnual, setIsAnnual, handlePremiumSubscription) => {
    switch (selectedPlan) {
      case 'Annual University':
        return null;
      case 'Annual School':
        return (
          <div className="Plans__unselected-plan-container">
            <UniversityPlan
              isPremium={false}
              isLoading={isLoading}
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
              priceId={"price_1QRpr1HFdskyUb7j9bONPjNt"}
              handlePremiumSubscription={handlePremiumSubscription}
            />
          </div>
        );
      default: return (
        <div className="Plans__unselected-plan-container">
           <AnnualPlans 
              isPremium={false}
              isLoading={isLoading}
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
              handlePremiumSubscription={handlePremiumSubscription}
            />            
        </div>)
    };
  };
  
  return { renderSelectedPlan, renderUnselectedPlan };
};