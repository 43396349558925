import React from "react";
import "./priceOption.scss";
import PropTypes from "prop-types";
import { Check } from "lucide-react";

const PriceOption = ({ option, title }) => {
  return (
    <li className={`PriceOption ${title === 'Piano Accademico' ? 'option-scolastico' : title === 'Piano Annuale' && 'option-universitario'}`}>
      <Check className={title === 'Piano Accademico' ? "PriceOption__icon__scolastico" : "PriceOption__icon"} alt="check" />
      {option}
    </li>
  );
};

PriceOption.propTypes = {
  option: PropTypes.string.isRequired,
};

export default PriceOption;
