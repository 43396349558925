import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import userReducer from './userReducer';
import documentReducer from './documentReducer';
import authReducer from './authReducer';
import leadScoreReducers from './leadScoreReducers';
import docUploadProgressReducer from './docUploadProgress';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  document: documentReducer,
  firestore: firestoreReducer,
  docUploadProgress: docUploadProgressReducer,
  leadScore: leadScoreReducers,
});
