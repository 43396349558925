export const initialState = {
  uploadNotes: [],
  processedNotes: [],
  disabledForm: false,
  isSaved: false,
  isCombined: false,
};

// reducer upload notes
export const reducer = (state, action) => {
  switch (action.type) {

    case "SET__IS__COMBINED": {
      return { ...state, isCombined: action.payload };
    }

    case "SET__UPLOAD_NOTES": {
      return { ...state, uploadNotes: [...action.payload] };
    }

    case "HANDLE__DISABLED_FORM":
      return { ...state, disabledForm: !state.disabledForm };

    case "HANDLE_IS_SAVED":
      return { ...state, isSaved: !state.isSaved };

    case "RESET":
      return {
        ...state,
        processedNotes: [],
      };

    default:
      return state;
  }
};
