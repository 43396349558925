import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Heading from "@ui/heading/heading";
import PriceCard from "@containers/priceCard/priceCard";


import "./price.scss";
import { MonthlyPlan } from "../../plans/MonthlyPlan/MonthlyPlan";
import { SubscriptionModes } from "../../../containers/priceCard/SubscriptionModes";
import { AnnualPlans } from "../../plans/AnnualPlans/AnnualPlans";

const Price = () => {
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);  

  return (
    <div id="price" className="Price">
      <div className="container">
        <div className="justify-center row">
          <div className="col-xs-12 col-lg-8">
            <Heading title="Piani e Prezzi" heading="h2" />
            <p className="Price__text">
              Scegli il piano che meglio si adatta alle tue esigenze e inizia a migliorare il tuo metodo di studio oggi stesso.
            </p>
          </div>
          <SubscriptionModes isAnnual={isAnnual} setIsAnnual={setIsAnnual} />
          {isAnnual 
            ? 
              <AnnualPlans 
                isAnnual={isAnnual}
                setIsAnnual={setIsAnnual}
                onClick={() => navigate("/signUp")}
                isLanding={true}
              /> 
              
            : 
              <div className="col-xs-12">
                <div className="items-center justify-center Price__card-row row g-5 gx-sm-5">
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <PriceCard
                      title="Prova Gratuita"
                      cost="0"
                      method="al mese"
                      options={["Caricamento fino a 2 documenti", "Ripetizione documento", "Flashcards automatiche", "Assistenza"]}
                      buttonText="Iscriviti Gratis"
                      onClick={() => navigate("/signUp")}
                      freeNote="Nessuna carta di credito richiesta per la registrazione"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <MonthlyPlan 
                      isAnnual={isAnnual}
                      setIsAnnual={setIsAnnual}
                      isLanding={true}
                      onClick={() => navigate("/signUp")}
                    />
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  );
};

// Definizione dei prop types
Price.propTypes = {
  navigate: PropTypes.func,
};

export default Price;
