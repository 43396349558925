import React, { useEffect, useState } from "react";
import Spinner from "@ui/spinner/spinner";
import { useSelector } from "react-redux";
import "./documentsQALoader.scss";

const DocumentsQALoader = () => {
  
  const [progress, setProgress] = useState(0);
  const file = useSelector(state => state.docUploadProgress);

  useEffect(() => {
    let intervalId;

    if (progress < 100) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress + 5 >= 99) {
            clearInterval(intervalId);
            return 95;
          } else {
            return prevProgress + 5;
          }
        });
      }, 2000); 
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [progress]);

  const { isGenerate } = useSelector(state => state.document);

  return (
    <div data-testid="documents-qa-loader" className="DocumentsQALoader">
      <Spinner size="md" />
      <p className="DocumentsQALoader__loading-process">{isGenerate ? 'Caricamento file in corso' : 'Estrazione domande e risposte dai documenti caricati'}</p>
      { !isGenerate || file.fileType === 'audio' ?
        <div>  
          <div className="DocumentsQALoader__progressBar-container">
            <div
              className={`DocumentsQALoader__progressBar-container__progress`}
              style={{ width: `${progress}%` }}
            >
            </div>
          </div>
        </div>
      
        : null
      }
    </div>
  );
};

export default DocumentsQALoader;
