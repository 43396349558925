import { SET_LIMIT_REACHED, SET_POPUP_VIEWD, UPGRAGE_CLICKED, LAST_POPUP_DATE, SET_LIMIT_EXAMS_REACHED } from './types';

export const setLimitReached = (limitReached) => {
  return {
    type: SET_LIMIT_REACHED,
    payload: limitReached,
  };
};

export const setLimitExamsReached = () => {
  return {
    type: SET_LIMIT_EXAMS_REACHED,
  };
};

export const setPopupViewed = () => {
  return {
    type: SET_POPUP_VIEWD,
  };
};  

export const setUpgradeClicked = () => {
  return {
    type: UPGRAGE_CLICKED,
  };
};  

export const setLastPopupDate = (lastPopupDate) => {
  return {
    type: LAST_POPUP_DATE,
    payload: lastPopupDate,
  };
};    
