import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./documentsQA.scss";
import useGetDocuments from "./hooks/useGetDocuments";
import useChangeDocument from "./hooks/useChangeDocument";
import DocumentsQALoader from "./documentsQALoader/documentsQALoader";
import DocumentsQAInfo from "./documentsQAInfo/documentsQAInfo";
import DocumentQA from "./documentQA/documentQA";
import DocumentsQAActions from "./documentsQAActions/documentsQAActions";
import DocumentsQASaveButton from "./documentsQASaveButton/documentsQASaveButton";
import { setSubmitDocState } from "@redux/actions/documentActions";
import { useFeedback } from "./hooks/useFeedback";
import { setFlashcardsQA } from "../../../redux/actions/documentActions";

const DocumentsQA = () => {
 const dispatch = useDispatch();
 const { resetDocsQA } = useGetDocuments();
 const { currentDocument, previousDocument, nextDocument } = useChangeDocument();
 const [documentsWithTempTitles, setDocumentsWithTempTitles] = useState([]);
 const [currentState, setCurrentState] = useState(false);

 const {
   documentsQA,
   isGenerate,
   isLoading,
   tags,
   summary,
   processedNotes,
   isSubmitting: submitState
 } = useSelector(state => state.document);

 const qa = useSelector(state => state.document);
 
 // Inizializza i documenti con titoli temporanei
 useEffect(() => {
  //save qa for flashcards in redux
  dispatch(setFlashcardsQA(qa.documentsQA[0]?.extractedQA));
  
   if (documentsQA.length > 0) {
     setDocumentsWithTempTitles(documentsQA.map(doc => ({
       ...doc,
       originalTitle: doc.title // Mantiene il titolo originale
     })));
   }
 }, [documentsQA, dispatch]);

 // Gestione loading state
 useEffect(() => {
   if (!isLoading && currentState) {
     dispatch(setSubmitDocState(false));
   } else if (isLoading) {
     setCurrentState(isLoading);
   }
 }, [isLoading, currentState, dispatch]);

 const {
   feedbackStates,
   handleFeedback,
   resetFeedback,
   activeFeedbackCount
 } = useFeedback(
   documentsQA[currentDocument]?.examExtractedQA || [],
   (index, isHelpful) => {
     const totalQuestions = documentsQA[currentDocument]?.examExtractedQA?.length || 0;
     console.log(`Feedback per domanda ${index + 1}/${totalQuestions}: ${isHelpful ? '✅' : '❌'}`);
   }
 );

  const currentDocumentData = documentsQA[currentDocument]; 
  const documentTag = currentDocumentData ? currentDocumentData.tags : null; 

 // Handler per aggiornare il titolo temporaneo
 const handleUpdateTitle = useCallback((index, newTitle) => {
   console.log('📝 Aggiornamento titolo locale:', {
     index,
     newTitle,
     originalTitle: documentsWithTempTitles[index]?.originalTitle
   });
  
   setDocumentsWithTempTitles(prevDocs => {
     const newDocs = [...prevDocs];
     if (newDocs[index]) {
       newDocs[index] = {
         ...newDocs[index],
         title: newTitle,
         lastModified: Date.now()
       };
     }
     return newDocs;
   });
 }, [documentsWithTempTitles]);


 // Handler per il reset combinato
 const handleReset = useCallback(() => {
   console.log('🔄 Reset documenti e feedback');
   resetFeedback();
   resetDocsQA();
   setDocumentsWithTempTitles(documentsQA.map(doc => ({ ...doc })));
 }, [resetFeedback, resetDocsQA, documentsQA]);

 // Prepara i documenti con feedback per il salvataggio
 const documentsToSave = useMemo(() => {
    return documentsWithTempTitles.map((doc, docIndex) => {
      const updatedDoc = {
        ...doc,
        tags: tags || [],
        summary: summary,
        extractedQA: docIndex === currentDocument ?
         doc.examExtractedQA?.map((qa, qaIndex) => ({
           ...qa,
           feedback: feedbackStates[qaIndex]
         })) || [] :
         doc.examExtractedQA,
     };
    
     delete updatedDoc.originalTitle; // Rimuove il campo temporaneo
     return updatedDoc;
   });
 }, [documentsWithTempTitles, currentDocument, feedbackStates, tags, summary]);


 if (isLoading || submitState) {
   return <DocumentsQALoader />;
 }


 if (!processedNotes.length || isGenerate) {
   return <DocumentsQAInfo />;
 }

 return (
   <div className="DocumentsQA">
     <div className="DocumentsQA__list">
       {documentsWithTempTitles.map((document, index) => {
         const validDocument = {
           ...document,
           extractedQA: document.examExtractedQA?.map(qa => ({
             ...qa,
             answers: qa.answers || qa.answer || ""
           })) || []
         };

         const isCurrentDoc = currentDocument === index;
        
         return (
           <DocumentQA
             key={document.id || index}
             index={index}
             id={document.id}
             isActive={isCurrentDoc}
             documentsQA={documentsWithTempTitles}
             documentTag={documentTag}             
             feedbackStates={isCurrentDoc ? feedbackStates : []}
             onUpdateTitle={(newTitle) => handleUpdateTitle(index, newTitle)}
             onFeedback={(questionIndex, isHelpful) => {
               if (isCurrentDoc) {
                 handleFeedback(questionIndex, isHelpful);
               }
             }}
             {...validDocument}
           />
         );
       })}
     </div>


     <div className="DocumentsQA__footer">
       <div className="DocumentsQA__footer-content">
         <DocumentsQAActions
           currentDocument={currentDocument}
           onPrevious={previousDocument}
           onNext={nextDocument}
           totalDocuments={documentsWithTempTitles.length}
         />
         <DocumentsQASaveButton
           documentsQA={documentsToSave}
           feedbackStates={feedbackStates}
           activeFeedbackCount={activeFeedbackCount}
           onReset={handleReset}
         />
       </div>
     </div>
   </div>
 );
};


export default DocumentsQA;


