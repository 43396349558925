import React from 'react'
import './searchbar.scss'
import PropTypes from 'prop-types'

import Input from '@ui/input/input'
import { Search } from 'lucide-react'


const Searchbar = ({ placeholder, ...props }) => {
  return (
    <div data-testid="searchbar" className='Searchbar'>
      <Search className='Searchbar__icon' size={20} />
      <Input className='Searchbar__input' type='search' placeholder={placeholder} {...props} />
    </div>
  )
}

// Searchbar prop types
Searchbar.propTypes = {
  placeholder: PropTypes.string.isRequired,
}

export default Searchbar
